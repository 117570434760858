<template>
  <div class="main">
    <div class="agency-item" v-for="(items, index) in agencyData" :key="index">
      <div class="title">{{ items.TODONAME }}</div>
      <div class="date"><van-icon name="clock-o" />{{ items.TODODATE }}</div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import Http from "../../../api/http";
export default {
  setup() {
    const agencyData = ref([]);
    const active = ref("E");
    const getList = () => {
      Http.get(
        {
          method: "queryProcedure",
          procedure: "SP_HOME_REPORT_SUMMARY",
          procedureParams: "${token_user_id}",
          procedureResult: "list1|list2|list3|list4|list5"
        },
        false,
        true
      )
        .then(res => {
          var list5 = res.map.list5;
          agencyData.value = list5;
        })
        .catch(() => {});
    };
    onMounted(() => {
      getList();
    });
    return {
      agencyData,
      active
    };
  }
};
</script>
<style scoped>
.agency-item {
  width: calc(100% - 20px);
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.agency-item .title {
  font-size: 14px;
  color: #000000;
  font-family: "regular";
}
.agency-item .date {
  font-family: "regular";
  color: #aaaaaa;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.agency-item .date .van-icon {
  margin-right: 4px;
}
</style>
